:root {
    --mint: #48ffd5ff;
    --dark: #041726;
    --medium: #052541;
    --light: #e6e9ec;
  
  
  }

.active{
    color: var(--mint);
}

.box-drawer {
  margin-top: -3em;
}